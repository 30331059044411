import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage

    const urlsExcludedForBearerHeader = [
      '/login',
      `${window.location.origin}/version.json`
    ]
    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem('token')
      )}`
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // erro diferente de 401
    if (error.response.status !== 401) {
      return Promise.reject(error)
    }

    console.log("ERRO STATUS:: "+error.response.status)
    console.log("ERRO URL:: "+error.config.url)
    // Se foi o refreshToken que retornou erro 401, desloga o usuário
    if (error.config.url == '/refresh-token/') {
      console.log('refreshToken falhou: '+JSON.stringify(error,null,2))
      store.dispatch('userLogout')
      return new Promise((resolve, reject) => {
        reject(error);
      })
    }

    // Se não for o Login, tenta novamente com o novo token
    if (error.config.url != '/login') {
      return store.dispatch('refreshToken')
      .then(() => {

        console.log('atualizando token e enviando requisição novamente')

        const config = error.config
        config.headers.Authorization = `Bearer ${JSON.parse(
          localStorage.getItem('token')
        )}`

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        })

      })
      .catch((error) => {
        Promise.reject(error);
      })
    }else{
      // caso contrário apenas retorna o erro
      return new Promise((resolve, reject) => {
        reject(error);
      })

    }
  })


// eslint-disable-next-line no-shadow
Plugin.install = Vue => {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      }
    },
    $axios: {
      get() {
        return axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
