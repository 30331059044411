import Vue from 'vue'
import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/auth'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
  user: state => state.user,
  token: state => state.token,
  isTokenSet: state => state.isTokenSet,
}

const actions = {

  adminLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {

      commit(types.SHOW_CENTER_LOADING, true)

      api
        .adminLogin(payload)
        .then(response => {
          if(response.status === 200) {

            //var encoded = btoa(JSON.stringify(response.data))
            var encoded = btoa(unescape(encodeURIComponent(JSON.stringify(response.data))))
            window.open(process.env.VUE_APP_PUBLIC_SITE+"/admin/login?token="+encoded)

            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          commit(types.SHOW_BTN_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  userLogin({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_TOP_LOADING, true)
      commit(types.SHOW_BTN_LOADING, true)
      api
        .userLogin(payload)
        .then(response => {

          if (response.status === 200) {

            if(response.data.data.user.role == 'partner'){
              //console.log("Login JWT Response: "+JSON.stringify(Vue.$jwt.decode(response.data.token),null,2))
              window.localStorage.setItem(
                'user',
                JSON.stringify(response.data.data.user)
              )
              window.localStorage.setItem(
                'token',
                JSON.stringify(response.data.token)
              )
              window.localStorage.setItem(
                'refreshToken',
                JSON.stringify(response.data.refreshToken)
              )
              window.localStorage.setItem(
                'tokenExpiration',
                JSON.stringify(Vue.$jwt.decode(response.data.token).exp)
              )

              // //console.log('response.data.data.events '+JSON.stringify(response.data.data.events,null,2))

              commit(types.SAVE_USER, response.data.data.user)
              commit(types.SAVE_TOKEN, response.data.token)
              commit(types.SHOW_BTN_LOADING, false)

              buildSuccess(
                null,
                commit,
                resolve,
                router.push({
                  name: 'dashboard'
                }, () => {})
              )
            }else{

              var roleError = {
                response: {
                  status: 200,
                  data: {
                    errors: {
                      msg: "INVALID_USERNAME_OR_PASSWORD"
                    }
                  }
                }
              }
              handleError(roleError, commit, reject)
            }
          }
        })
        .catch(error => {
          console.log('-2-')
          //console.log('login error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_BTN_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },
  refreshToken({ commit }) {
    console.log("wait: refreshing token... "+JSON.parse(window.localStorage.getItem('refreshToken')))

    commit(types.SHOW_TOP_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .refreshToken({token: JSON.parse(window.localStorage.getItem('refreshToken'))})
        .then(response => {

          //console.log('refreshToken Response: '+JSON.stringify(response,null,2))

          if (response.status === 200) {
            window.localStorage.setItem(
              'user',
              JSON.stringify(response.data.data.user)
            )
            window.localStorage.setItem(
              'token',
              JSON.stringify(response.data.token)
            )
            window.localStorage.setItem(
              'tokenExpiration',
              JSON.stringify(Vue.$jwt.decode(response.data.token).exp)
            )
            window.localStorage.setItem(
              'refreshToken',
              JSON.stringify(response.data.refreshToken)
            )

            commit(types.SAVE_USER, response.data.data.user)
            commit(types.SAVE_TOKEN, response.data.token)
            commit(types.SHOW_TOP_LOADING, false)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve()
          }
        })
        .catch(error => {
          console.log('refreshToken ERROR: '+JSON.stringify(error,null,2))
          actions.userLogout({ commit })
          handleError(error, commit, reject)
        })
    })
  },
  autoLogin({ commit }) {
    commit(types.SAVE_USER, JSON.parse(localStorage.getItem('user')))
    commit(types.SAVE_TOKEN, JSON.parse(localStorage.getItem('token')))
  },

  openRouteLogout({ commit }) {
    console.log('- userLogout -')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    // window.localStorage.removeItem('tokenExpiration')
    // window.localStorage.removeItem('refreshToken')
    //window.localStorage.removeItem('refreshToken')
    commit(types.SHOW_BTN_LOADING, false)
    commit(types.SHOW_TOP_LOADING, false)
    commit(types.SHOW_CENTER_LOADING, false)

    commit(types.LOGOUT)
  },


  userLogout({ commit }) {
    console.log('- userLogout -')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('tokenExpiration')
    window.localStorage.removeItem('refreshToken')

    commit(types.SHOW_BTN_LOADING, false)
    commit(types.SHOW_TOP_LOADING, false)
    commit(types.SHOW_CENTER_LOADING, false)

    commit(types.LOGOUT)
    if(router.currentRoute.name != 'login'){
      router.push({
        name: 'login'
      })
    }

  },
}

const mutations = {
  [types.SAVE_USER](state, value) {
    state.user = value
  },
  [types.SAVE_TOKEN](state, token) {
    state.token = token
    state.isTokenSet = true
  },
  [types.LOGOUT](state) {
    state.user = null
    state.isTokenSet = false
  }
}

const state = {
  user: null,
  token: JSON.parse(!!localStorage.getItem('token')) || null,
  isTokenSet: !!localStorage.getItem('token')
}

export default {
  state,
  getters,
  actions,
  mutations
}
