import Vue from 'vue'
import App from './App.vue'
import VueJWT from 'vuejs-jwt' // descriptografar token
import vuetify from './plugins/vuetify'
import router from './router'
import './plugins/axios'
import { store } from '@/store'
import i18n from '@/plugins/i18n'
import '@/plugins/common'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(VueJWT)

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
    moment,
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    if (store.getters.isTokenSet) {
      store.dispatch('autoLogin')
    }
  }
}).$mount('#app')
