import * as types from '@/store/mutation-types'
import api from '@/services/api/dashboard'
import { handleError } from '@/utils/utils.js'

const getters = {
  dashData: state => state.dashData
}

const actions = {
  getDash({ commit }) {

    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve, reject) => {
      api.getDash()
      .then(response => {
        if(response.status === 200) {
          commit(types.DASH_DATA, response.data)
          commit(types.SHOW_CENTER_LOADING, false)
        }
      })
      .catch(error => {
        commit(types.SHOW_CENTER_LOADING, false)
        handleError(error, commit, reject)
      })

    })
  }
}

const mutations = {
  [types.DASH_DATA](state, value) {
    state.dashData = value
  }
}

const state = {
  dashData: []
}

export default {
  actions,
  state,
  getters,
  mutations
}
