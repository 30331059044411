<template lang="pug">
v-navigation-drawer(app v-model="drawer" permament fixed color="primary" v-if="isTokenSet")

  v-list
      v-list-item(:to="{ name: 'profile-account'}")
        v-list-item-icon
          v-icon(color="white") fas fa-user-circle
        v-list-item-content
          v-list-item-title(class="white--text body-2 font-weight-bold") Meus dados

  v-list
    v-list-item(:to="{ name: 'dashboard'}")
      v-list-item-icon 
        v-icon(color="white") fas fa-clipboard-list
      v-list-item-content
        v-list-item-title(class="white--text body-2 font-weight-bold") Painel

  v-list
    v-list-item(v-for="item in directItems" :key="item.title" :to="{ name: item.link}")
      v-list-item-icon 
        v-icon(color="white") {{ item.icon }}
      v-list-item-content
        v-list-item-title(class="white--text body-2 font-weight-bold") {{ item.title }}


  template
    div(class="pa-2")
      v-list
        v-list-item(v-for="item in singleItems" :key="item.title")
          v-btn(outlined color="white" block @click="item.action")
            v-icon(left) {{ item.icon }}
            | {{ item.title }}

  template
    div(class="pa-2")
      v-list
        v-list-item
          v-btn(outlined color="white" block @click="logout")
            v-icon(left) mdi-logout
            | Sair

</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'SideMenu',
  data () {
    return {

      drawer: true,

      directItems: [
        { title: 'Minhas indicações', link:'partners', icon:'fas fa-users' },
        { title: 'Relatório de comissões', link:'financial-report', icon:'fas fa-comments-dollar' },
        { title: 'Resgates', link:'financial-withdraw', icon:'fas fa-hand-holding-usd' },
      ],
      singleItems:[
      ]
    }
  },
  computed: {
    ...mapGetters(['isTokenSet']),
  },
  methods: {
    ...mapActions(['userLogout']),
    showHide(){
      this.drawer = true
    },
    logout(){ this.userLogout() }
  },
  mounted(){

  },
  watch: {
  }
}
</script>
<style scoped>
  .listTitle{
    background: #4DC5C1;
  }
  .gradientBg{
    background: #4DC5C1;
        background: -webkit-linear-gradient(to right, #4DC5C1, #60ECE6);
        background: linear-gradient(to right, #4DC5C1, #60ECE6);
  }
</style>
