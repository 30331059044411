import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes:
      {
        light: {
          primary: '#60DCD6',
          secondary: '#D2CDCA',
          accent: '#5D737E',
          success: '#4CAF50',
          info: '#2196F3',
          warning: colors.yellow.darken3,
          error: colors.red.accent3,
          appBar: colors.grey.lighten5,
          warningFont: colors.grey.darken2,
          buttons: '#FFA6A0',
          invitationTitle: '#4DC5C1'
        }
      }
  }
})

export default vuetify
