export default [
  {
    path: '/partners/partners',
    name: 'partners',
    meta: {
      requiresAuth: true,
      title: 'Meus indicados'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/partners/Partners.vue'),
  }
]
