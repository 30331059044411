export default [
  {
    path: '/financial/bank',
    name: 'financial-bank',
    meta: {
      requiresAuth: true,
      title: 'Conta bancária'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Bank.vue'),
  },
  {
    path: '/financial/report',
    name: 'financial-report',
    meta: {
      requiresAuth: true,
      title: 'Relatórios'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Report.vue'),
  },
  {
    path: '/financial/details/:eventId?',
    name: 'financial-details',
    meta: {
      requiresAuth: true,
      title: 'Detalhamento'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Details.vue'),
  },
  {
    path: '/financial/withdraw',
    name: 'financial-withdraw',
    meta: {
      requiresAuth: true,
      title: 'Saques'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/Withdraw.vue'),
  },
  {
    path: '/financial/withdrawalconfirmation/:hash?',
    name: 'withdrawal-confirmation',
    meta: {
      title: 'Confirmação de resgate'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/financial/WithdrawalConfirmation.vue'),
  },
]
