import * as types from '@/store/mutation-types'
import api from '@/services/api/financial'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  comissionReport: state => state.comissionReport,
  commissionDetails: state => state.commissionDetails,
  withdrawConfirmed: state => state.withdrawConfirmed,
  withdrawsReport: state => state.withdrawsReport,
  withdrawRequested: state => state.withdrawRequested,
  sendedSuccessfully: state => state.sendedSuccessfully
}

const actions = {
  getComissionsReport({ commit }, payload) {

    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve, reject) => {
      api.getComissionsReport(payload)
      .then(response => {
        if(response.status === 200) {
          // console.log(JSON.stringify(response.data,null,2))

          commit(types.COMISSION_REPORT, response.data)
          commit(types.SHOW_CENTER_LOADING, false)
          resolve(true)
        }
      })
      .catch(error => {
        commit(types.SHOW_CENTER_LOADING, false)
        handleError(error, commit, reject)
      })

    })
  },

  getCommissionsByEvent({ commit }, payload) {

    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve, reject) => {
      api.getCommissionsByEvent(payload)
      .then(response => {
        if(response.status === 200) {
          commit(types.COMMISSION_DETAILS, response.data.docs)
          commit(types.SHOW_CENTER_LOADING, false)
          resolve(true)
        }
      })
      .catch(error => {
        commit(types.SHOW_CENTER_LOADING, false)
        handleError(error, commit, reject)
      })

    })
  },

  confirmWithdraw({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .confirmWithdraw(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            commit(types.WITHDRAW_CONFIRMED, true)
            resolve(true)
          }
        }).catch(error => {
          //console.log('postGuests error: '+JSON.stringify(error,null,2))
          commit(types.WITHDRAW_CONFIRMED, false)
          handleError(error, commit, reject)
        })
    })
  },

  getWithdraws({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getWithdrawsReport(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            commit(types.WITHDRAWS_REPORT, response.data)
            resolve(true)
          }
        }).catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  requestWithdraw({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .requestWithdraw(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            commit(types.WITHDRAW_REQUESTED, true)
            resolve(true)
          }
        }).catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  resendConfirmation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .resendConfirmation(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SENDED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          handleError(error, commit, reject)
        })
    })
  },


}

const mutations = {
  [types.COMISSION_REPORT](state, value) {
    state.comissionReport = value
  },
  [types.COMMISSION_DETAILS](state, value) {
    state.commissionDetails = value
  },
  [types.WITHDRAW_CONFIRMED](state, value) {
    state.withdrawConfirmed = value
  },
  [types.WITHDRAWS_REPORT](state, value) {
    state.withdrawsReport = value
  },
  [types.WITHDRAW_REQUESTED](state, value) {
    state.withdrawRequested = value
  },
  [types.SENDED_SUCCESSFULLY](state, value) {
    state.sendedSuccessfully = value
  }
}

const state = {
  comissionReport: [],
  commissionDetails: [],
  withdrawConfirmed: false,
  withdrawsReport: [],
  withdrawRequested: false,
  sendedSuccessfully: false
}

export default {
  actions,
  state,
  getters,
  mutations
}
