export default [
    {
      path: '/profile/account',
      name: 'profile-account',
      meta: {
        requiresAuth: true,
        title: 'Minha conta'
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/components/profile/Account.vue'),
    }
  ]
  