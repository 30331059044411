import * as types from '@/store/mutation-types'
import api from '@/services/api/partner'
import { handleError } from '@/utils/utils.js'

const getters = {
  partners: state => state.partners
}

const actions = {
  getPartners({ commit }, payload) {

    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve, reject) => {
      api.getPartners(payload)
      .then(response => {
        if(response.status === 200) {
          commit(types.PARTNERS, response.data.docs)
          commit(types.SHOW_CENTER_LOADING, false)
          resolve(true)
        }
      })
      .catch(error => {
        commit(types.SHOW_CENTER_LOADING, false)
        handleError(error, commit, reject)
      })

    })
  }
}

const mutations = {
  [types.PARTNERS](state, value) {
    state.partners = value
  }
}

const state = {
  partners: []
}

export default {
  actions,
  state,
  getters,
  mutations
}
