import axios from 'axios'

export default {
  getComissionsReport(params) {
    return axios.get(`/promocodes/partner/report`, { params })
  },

  getWithdrawsReport() {
    return axios.get(`/promocodes/partner/withdraws`)
  },

  requestWithdraw() {
    return axios.get(`/promocodes/partner/withdraw/request`)
  },

  confirmWithdraw(payload) {
    return axios.post('/promocodes/partner/withdraw/validate/', payload )
  },

  resendConfirmation(payload) {
    return axios.post('/promocodes/partner/withdraw/validate-resend/', payload )
  },

  getCommissionsByEvent(params) {
    return axios.get(`/promocodes/partner/commissions/${params.id}`)
  }

}
