<template lang="pug">
  v-app
    SideMenu(v-if="isTokenSet" ref="drawer")
    div
      v-app-bar(app color="appBar")
        TopLoading
        div(class="d-flex align-center")
          router-link(:to="{ name: 'dashboard' }")
            v-img(alt="Logo" class="shrink mr-2" contain :src="`${imgPath}/site/imgs/logo-ifraldas.png`" transition="scale-transition" width="140")
        v-spacer
        v-app-bar-nav-icon(v-if="isTokenSet" @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer" class="hidden-lg-and-up")

    v-main
      router-view
      CenterLoading
</template>

<script>
import { mapGetters } from 'vuex'

import SideMenu from './components/core/SideMenu'
import TopLoading from './components/core/TopLoading'
import CenterLoading from './components/core/CenterLoading'

export default {
  name: 'App',

  components: {
    SideMenu,
    TopLoading,
    CenterLoading
  },

  props: {
    attrs: {
      type: Object,
      default: () => ({}),
    }
  },

  data: () => ({
    imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
  }),

  methods:{

  },
  computed:{
    ...mapGetters(['isTokenSet']),
    getRoutePath() {
      return this.$route.path;
    }
  },
  mounted(){

  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'iFraldas - Parceiros'
    }
  }

}
</script>
<style>

</style>
