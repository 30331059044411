// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// auth
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
// user
export const USER_ID = 'USER_ID'
export const USER_DATA = 'USER_DATA'
export const BANKS = 'BANKS'
// loading
export const SHOW_CENTER_LOADING = 'SHOW_CENTER_LOADING'
export const SHOW_TOP_LOADING = 'SHOW_TOP_LOADING'
export const SHOW_BTN_LOADING = 'SHOW_BTN_LOADING'
// dashboard
export const DASH_DATA = 'DASH_DATA'
// financial-report
export const COMISSION_REPORT = 'COMISSION_REPORT'
export const COMMISSION_DETAILS = 'COMMISSION_DETAILS'
// financial-withdraw
export const CONFIRMATION_SENT = 'CONFIRMATION_SENT'
export const WITHDRAW_CONFIRMED = 'WITHDRAW_CONFIRMED'
export const WITHDRAWS_REPORT = 'WITHDRAWS_REPORT'
export const WITHDRAW_REQUESTED = 'WITHDRAW_REQUESTED'
export const SENDED_SUCCESSFULLY = 'SENDED_SUCCESSFULLY'
// PARTNERS
export const PARTNERS = 'PARTNERS'
