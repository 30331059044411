import api from '@/services/api/users'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  userId: state => state.userId,
  userData: state => state.userData,
  banks: state => state.banks
}

const actions = {
  getUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .getUser(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.USER_DATA, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },
  getBanks({ commit }){
    return new Promise((resolve, reject) => {
      api
        .getBanks()
        .then(response => {
          if (response.status === 200) {
            commit(types.BANKS, response.data.docs)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },
  updateUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .updateUser(payload)
        .then(response => {

          if(response.status === 200) {
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
            if(payload.bankData != undefined){
              router.push({
                name: 'financial-withdraw'
              }, () => {})
            }

          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  }
}

  const mutations = {
    [types.USER_ID](state, value) {
      state.userId = value
    },
    [types.USER_DATA](state, value) {
      state.userData = value
    },
    [types.BANKS](state, value) {
      state.banks = value
    },
  }

  const state = {
    userId: null,
    userData: null,
    banks: []
  }

  export default {
    state,
    getters,
    actions,
    mutations
  }
