import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/router/routes'
import { store } from '@/store'
import * as types from '@/store/mutation-types'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
  scrollBehavior () { // observar. Criado para abrir as páginas sempre no topo
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isTokenSet = store.getters.isTokenSet

  if (requiresAuth && !isTokenSet) {
    return next('/login')
  }

  store.commit(types.SUCCESS, null)
  store.commit(types.ERROR, null)
  return next()

})

export default router
