import axios from 'axios'

export default {

  updateUser(payload) {
    return axios.patch('/users/update/', payload)
  },
  getUser(params){
    return axios.get(`/users/${params.id}`)
  },
  getBanks(){
    return axios.get('/users/banks')
  }

}
